export const NAV = {
  ACCOUNT: 'account',
  DASHBOARD: 'dashboard',
  USER_INFO: 'users/info/:id',
  USER_EDIT: 'users/edit/:id',
  USER_ADD: 'users/add',
  USER_LIST: 'users',
  FEEDBACK_EDIT: 'feedbacks/edit/:id',
  FEEDBACK_LIST: 'feedbacks',
  EMAIL_TEMPLATES: 'email-templates',
  EMAIL_HISTORY: 'email-history',
  EMAIL_CREATE: 'email-templates/create',
  EMAIL_EDIT: 'email-templates/edit/:id',
  LOGS_LIST: 'app-logs',
  // APP_STATISTICS: 'app-statistics',
  APP_STATISTICS_EVENTS: 'events',
  APP_STATISTICS_ADD: 'events/add',
  APP_STATISTICS_EDIT: 'events/edit/:id',
  LOGS: 'logs',
  // COMMON_SETTINGS: 'common-settings',
  UPDATE_SYSTEM: 'update-system',
  // SETTINGS: 'settings',
  CONFIGURATION_KEYS: 'configuration/keys',
  CONFIGURATION_KEYS_ADD: 'configuration/keys/add',
  CONFIGURATION_KEYS_EDIT: 'configuration/keys/edit/:id',
  LOGIN: 'login',
  MAIN: "/",
  NO_PAGE: "*",
  //------Template---------

  TASK_ADD: 'tasks/add/:id',
  TASK_EDIT: 'tasks/edit/:id',
  LANGUAGES: 'languages',
  LANGUAGES_EDIT: 'languages/edit/:id',
  PHRASES: 'phrases',
  TRANSLATION_ADD: 'phrases/info/add-translation/:id',
  TRANSLATION_EDIT: 'phrases/info/edit-tranlation/:id',
  PHRASES_INFO: 'phrases/info/:id',
  BLOGS: 'blogs',
  BLOGS_ADD: 'blogs/add',
  BLOGS_EDIT: 'blogs/edit/:id',
  BLOG_INFO: 'blogs/info/:id',
  BLOG_INFO_DATA: 'blogs/info/data/:blogId/:id',
  BLOG_INFO_DATA_ADD: 'blogs/info/data/add/:id',
  BLOG_INFO_DATA_EDIT: 'blogs/info/data/edit/:blogId/:id',
  RECORDS: 'records',
  RECORD_EDIT: 'records/edit/:id',
  RECORD_INFO: 'records/info/:id',
  DOCUMENTS: 'documents',
  DOCUMENTS_INFO: 'documents/info/:id',
  DOCUMENTS_ADD: 'documents/add',
  DOCUMENTS_EDIT: 'documents/edit/:id'
};
